import React from 'react';
import PropTypes from 'prop-types';
import ReactJson from 'react-json-view';
import { Grid, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import {
    Heading,
    Subtitle,
    APISection,
    APIHeader,
    APISubHeader
} from '../../component/Content/ContentStyles';
import {
    SubHeaderTitle
} from './SupportPageStyle';
import { Container, Section } from '../../globalStyles';
import {
    List,
    ListItem,
    DividerImgIcon,
    CursorPointer,
} from './SupportPageStyle';
import TableAPI from './TableAPI';

const OpenAPISection = (props) => {
    const {
        menuLeftData,
        divider,
        allowedHttp,
        allowedHttpData,
        headerData,
        serverResData
    } = props;

    const scrollTosection = (e, index) => {
        e.preventDefault();
        const element = document.getElementById(index + '-content');
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    };

    return (
        <Section
            padding='50px 0'>
            <Container>
                <Grid container spacing={3}>
                    <Box sx={{
                        '@media (max-width: 960px)': {
                            display: 'none'
                        }
                    }}
                    style={{paddingTop: '0px', marginTop:'-28px'}}
                    component={Grid}
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    >
                        {menuLeftData.map((data, i) => (
                            <div key={i} style={{ position: 'fixed', height: 'calc(100% - 110px)', overflow:'auto', }}>
                                <List margin="24px 0px 24px 0px">
                                    <ListItem>
                                        <strong>{data.title}</strong>
                                        {data?.subLink.map((item, i) => (
                                            <List key={i}>
                                                <CursorPointer>
                                                    <ListItem style={{fontSize:'14px'}} margin="14px 0px 14px 24px" onClick={(e) => scrollTosection(e, i)}>
                                                        {item}
                                                    </ListItem>
                                                </CursorPointer>
                                            </List>
                                        ))}
                                    </ListItem>
                                </List>
                            </div>
                        ))}
                    </Box>
                    <Box
                        component={Grid}
                        item
                        xs={12}
                        sm={12}
                        md={8}
                    >
                        <Heading
                            fontSize='2.75rem'
                        >
                            DrillBit API Overview
                        </Heading>
                        <Subtitle
                            width='100%'
                        >
                            DrillBit API is a service to check plagiarism, this guide helps to integrate in a convenient way.
                            If you have any prior knowledge of the REST services, it would be easy to start with integrations.
                        </Subtitle>
                        <TableAPI id="0-content"
                            headerTitle={allowedHttp}
                            subTitle='HTTP defines a set of request methods to indicate the desired action to be performed for a given resource. It can be as follows:'
                            tableCell1='HTTP Method'
                            tableCell2='Description'
                            allowedHttpData={allowedHttpData}

                        />
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <TableAPI id="1-content"
                            headerTitle='Header'
                            subTitle='These headers are required to make a successful request:'
                            tableCell1='Header'
                            tableCell2='Description'
                            allowedHttpData={headerData}

                        />
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <TableAPI id="2-content"
                            headerTitle='Description Of Usual Server Responses'
                            subTitle=''
                            tableCell1='HTTP Code'
                            tableCell2='Description'
                            allowedHttpData={serverResData}

                        />
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <SubHeaderTitle id="3-content">
                            Authorization and Authentication
                        </SubHeaderTitle>
                        <Subtitle
                            fontSize="1rem"
                            width='100%'
                        >
                            DRILLBIT REST API uses basic auth to authorize calls. In exchange for these credentials,
                            DRILLBIT provides access tokens on valid authentication called bearer tokens that you use for
                            authorization when making REST API requests.
                        </Subtitle>
                        <APIHeader>
                            URI
                        </APIHeader>
                        <APISubHeader>
                            Method Type : POST
                        </APISubHeader>
                        <APISection>
                            https://s1.drillbitplagiarismcheck.com/authentication/authenticate
                        </APISection>
                        <APIHeader>
                            Request Body
                        </APIHeader>
                        <APISection>
                            <ReactJson src={{
                                'username': 'username',
                                'password': 'your password'
                            }} />
                        </APISection>
                        <APIHeader>
                            Response
                        </APIHeader>
                        <APISection>
                            <ReactJson src={{
                                'token': 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdWJtaXNzaW9uQGluZGlh' + <br /> +
                                    'bmpvdXJuYWxzLmNvbSIsImV4cCI6MTY2Njk1OTk5OCwiaWF0IjoxNjY2OTQxOTk' + <br /> +
                                    '4fQ.wWfZ_NkHvY0o2OyunAwV0Ryc_jZP6HxVIm6o-HkpAyiQ_p7lHfxJ8qCW_gB_PlooVteuUKauYzwp4A5mLF4MVg',
                                'role': 'lim-instructor',
                                'username': 'username',
                                'name': 'Your Name'
                            }} />
                        </APISection>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="4-content">
                            <SubHeaderTitle>
                                Create Folder
                            </SubHeaderTitle>
                            <APIHeader>
                                URI
                            </APIHeader>         
                            <APISection>
                                https://s1.drillbitplagiarismcheck.com/pro/folder
                            </APISection>
                            <APISubHeader>
                                Method Type : POST
                            </APISubHeader>
                            <APISubHeader>
                                Header : BEARER TOKEN
                            </APISubHeader>                          
                            <APIHeader>
                                Request Body
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'folder_name': 'Pro folder',
                                    'exclude_reference': 'NO',
                                    'exclude_quotes': 'NO',
                                    'exclude_small_sources': 'NO',
                                    'grammar_check': 'NO',
                                    'db_studentpaper': 'YES',
                                    'db_publications': 'YES',
                                    'db_internet': 'YES',
                                    'institution_repository': 'YES',
                                    'exclude_phrases': 'YES',
                                    'phrases': {'p1' : 'phrases 1', 'p2' : 'phrases 2', 'p3':'Phrases 3'},
                                }} />
                            </APISection>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'status': 201,
                                    'message': 'Folder - Pro folder has been created successfully',
                                    'timeStamp': '06-06-2023 02:08:32',
                                    '_links': {
                                        'self':{
                                            'href': 'https://s1.drillbitplagiarismcheck.com:8087/pro/folder/285990'
                                        }
                                    }
                                }} />
                            </APISection>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="5-content">
                            <SubHeaderTitle>
                                File Upload
                            </SubHeaderTitle>         
                            <APIHeader>
                                URI
                            </APIHeader>                            
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/files/folder/{id}/singleFile'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}>id : Folder id should be provided in the URL request.</div>
                            <APISubHeader>
                                Method Type : POST
                            </APISubHeader>
                            <APISubHeader>
                                Header : BEARER TOKEN
                            </APISubHeader>                            
                            <APIHeader>
                                Request Body
                            </APIHeader>
                            <APISubHeader>
                                Content type: multipart/form-data
                            </APISubHeader>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                Name
                                            </TableCell>
                                            <TableCell>
                                                Value
                                            </TableCell>
                                            <TableCell>
                                                Type
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="left">authorName</TableCell>
                                            <TableCell align="left">Yati</TableCell>
                                            <TableCell align="left">String</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">title</TableCell>
                                            <TableCell align="left">Plag check</TableCell>
                                            <TableCell align="left">String</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">documentType</TableCell>
                                            <TableCell align="left">Assignment</TableCell>
                                            <TableCell align="left">String</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">plagiarismCheck</TableCell>
                                            <TableCell align="left">YES</TableCell>
                                            <TableCell align="left">String</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">grammarCheck</TableCell>
                                            <TableCell align="left">NO</TableCell>
                                            <TableCell align="left">String</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="left">file</TableCell>
                                            <TableCell align="left">Multipart object</TableCell>
                                            <TableCell align="left">Multipart file</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'status': 200,
                                    'message': 'File uploaded successfully.'
                                }} />
                            </APISection>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="6-content">
                            <SubHeaderTitle>
                                Submission Details
                            </SubHeaderTitle>
                            <APIHeader>
                                URI
                            </APIHeader>
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/pro/folder/{folder_id}/submission/{id}'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}>folder_id : Folder ID , id: Paper ID</div>
                            <APISubHeader>
                                Method Type : GET
                            </APISubHeader>
                            <APISubHeader>
                                Header : BEARER TOKEN
                            </APISubHeader>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'paper_id': 763103,
                                    'folder_id': 285990,
                                    'folder_name': 'Pro folder',
                                    'name': 'test',                                     
                                    'mail_id': 'rakesh@drillbitplagiarism.com',
                                    'title': 'sasas',                                   
                                    'date_up': '2023-06-06 14:22:52',
                                    'percent': '100',
                                    'total_char': '--',
                                    'total_words': '--',
                                    'total_sens': '--',
                                    'total_pages': '20',
                                    'file_length': '1019 kb',
                                    'repository_status': '0',
                                    'upload_time': '2020-12-25 15:11:39',
                                    'd_key': 'XYM05RSPBJVZ7MMKZR00',
                                    'status': 'active',
                                    'college_name': 'Drillbit Softtech India Pvt. Ltd',
                                    'original_file_name': 'Kantara_(film).pdf',                                    
                                    'language': 'English',
                                    'language1': 'English',
                                    'document_type': 'thesis',
                                    'grammar':'NA',
                                    'grammar_url': 'NA',
                                    'small_sources': 'NO',
                                    'exclude_references': 'NO',
                                    'exclude_quotes': 'NO',
                                    'alert_msg': 'Document contains 35% of Quotes..!@@@Document contains 49% of References/Bibliography..!',
                                    'reg_ex': 0,
                                    'flag': 0,
                                }
                                } />
                            </APISection>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="7-content">
                            <SubHeaderTitle>
                                File Download
                            </SubHeaderTitle>         
                            <APIHeader>
                                URI
                            </APIHeader>                   
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/analysis-gateway/api/download2/{paper_id}/{d_key}'}
                            </APISection>                            
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}>paper_id : Paper ID , d_key : get using submission details.</div>
                            <APISubHeader>
                                Method Type : GET
                            </APISubHeader>
                            <APISubHeader>
                                HEADER : BEARER TOKEN
                            </APISubHeader>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="8-content">
                            <SubHeaderTitle>
                                Notes
                            </SubHeaderTitle>
                            <div style={{fontSize:'14px', fontWeight:'600', marginTop:'1rem'}}>
                                <p>a. When a file is uploaded, it might take around 5-15 mins to generate result based on the number of pages and traffic.</p>
                            </div>
                            <div style={{fontSize:'14px', fontWeight:'600', marginTop:'1rem'}}>
                                <p>b. Initially when file is under process, the similarity/percent will be set to “--”..</p>
                            </div>
                            <div style={{fontSize:'14px', fontWeight:'600', marginTop:'1rem'}}>
                                <p>c. To get status of the upload file, Submissions Details (4) API will provide the details.</p>
                            </div>
                            <div style={{fontSize:'14px', fontWeight:'600', marginTop:'1rem'}}>
                                <p>d. File download / similarity report will be available when similarity/percent value is changed to a number from “--”, the same can be checked using submission details API.</p>
                            </div>
                            <div style={{fontSize:'14px', fontWeight:'600', marginTop:'1rem'}}>
                                <p>e. To receive callback/configure webhook from DrillBit when the similarity check process is completed, provide an API.</p>
                            </div>
                            <div style={{fontSize:'14px', fontWeight:'600', marginTop:'1rem'}}>
                                <p>f. To discuss further email us at : <br />yatheendra@drillbitplagiarism.com <br/> support@drillbitplagiarism.com</p>
                            </div>                            
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="9-content">
                            <SubHeaderTitle>
                               Folders List
                            </SubHeaderTitle>
                            <APIHeader>
                                URI
                            </APIHeader>     
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/pro/folders?page=0&size=25&field=ass_id&orderBy=desc'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}> QUERY PARAMS : page, size, field(just pass - ass_id), orderBy (desc or asc)</div>
                            <APISubHeader>
                                Method Type : GET
                            </APISubHeader>
                            <APISubHeader>
                                HEADER : BEARER TOKEN
                            </APISubHeader>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'folders': {
                                        'content': [
                                            {
                                                'folder_id': 163261,
                                                'folder_name': 'Ananya Sharma',
                                                'mail_id': 'rakesh@drillbitplagiarism.com',
                                                'ass_id': 56715,
                                                'percent': 3,
                                                'created_date': '2024-02-09 18:11:45',
                                                'end_date': '2024-06-22 00:00:00',
                                                'small_sources': 'NO',
                                                'ex_references': 'YES',
                                                'ex_quotes': 'YES',
                                                'grammar': 'NO',
                                                'ex_phrases': 'NO',
                                                'db_studentpaper': 'YES',
                                                'db_publications': 'YES',
                                                'db_internet': 'YES',
                                                'institution_repository': 'YES',
                                                'no_of_submissions': 8,
                                                'phrases': {
                                                    'pId': 316297,
                                                    'p1': 'Hello',
                                                },
                                                'email_notifications': 'NO',
                                            }
                                        ],
                                        'page': {
                                            'size': 24,
                                            'totalElements': 34,
                                            'totalPages': 2,
                                            'number': 0,
                                        },                                        
                                    },
                                }} />
                            </APISection>
                        </div>                    
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="10-content">
                            <SubHeaderTitle>
                                Submission List
                            </SubHeaderTitle>
                            <APIHeader>
                                URI
                            </APIHeader>    
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/pro/folder/{folder_id}/submissions?page=0&size=25&field=paper_id&orderBy=desc'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}> QUERY PARAMS : page, size, field(just pass - paper_id), orderBy (desc or asc)</div>
                            <APISubHeader>
                                Method Type : GET
                            </APISubHeader>
                            <APISubHeader>
                                HEADER : BEARER TOKEN
                            </APISubHeader>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'submissionsListResponse': {
                                        'content': [
                                            {
                                                'paper_id': 163261,
                                                'name': 'Ananya Sharma',
                                                'title': 'Contempt of court with reference to Justice CS Karnan The Honourable SC of India',
                                                'mail_id': 'rakesh@drillbitplagiarism.com',
                                                'ass_id': 56715,
                                                'percent': 3,
                                                'upload_time': '2020-10-15 16:10:26',
                                                'd_key': '22VU9KRUU7PL3XDQJDOW',
                                                'pages': '30',
                                                'file_size': '162',
                                                'language': 'English',
                                                'doc_type': 'Thesis',
                                                'links': []
                                            }
                                        ],
                                        'pageable': {
                                            'sort': {
                                                'sorted': false,
                                                'unsorted': true,
                                                'empty': true
                                            },
                                            'offset': 0,
                                            'pageSize': 25,
                                            'pageNumber': 0,
                                            'unpaged': false,
                                            'paged': true
                                        },
                                        'last': true,
                                        'totalElements': 1,
                                        'totalPages': 1,
                                        'size': 25,
                                        'number': 0,
                                        'sort': {
                                            'sorted': false,
                                            'unsorted': true,
                                            'empty': true
                                        },
                                        'numberOfElements': 1,
                                        'first': true,
                                        'empty': false
                                    },
                                    'status': 200,
                                    'message': 'success'
                                }} />
                            </APISection>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="11-content">
                            <SubHeaderTitle>
                                Delete Folder
                            </SubHeaderTitle>
                            <APIHeader>
                                URI
                            </APIHeader>                            
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/pro/folder/{folder_id}'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}> folder_Id : Folder id should be provided in the URL request</div>
                            <APISubHeader>
                                Method Type : DELETE
                            </APISubHeader>
                            <APISubHeader>
                                HEADER : BEARER TOKEN
                            </APISubHeader>
                            <APISection>
                                <ReactJson src={{                                    
                                    'status': 200,
                                    'message': 'Folder deleted successfully.',
                                    'timeStamp': '08-06-2023 02:55:27'
                                }} />
                            </APISection>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="12-content">
                            <SubHeaderTitle>
                                Edit Folder
                            </SubHeaderTitle>
                            <APIHeader>
                                URI
                            </APIHeader>      
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/pro/folder/{folder_id}'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}> folder_Id : Folder ID</div>
                            <APISubHeader>
                                Method Type : PUT
                            </APISubHeader>
                            <APISubHeader>
                                HEADER : BEARER TOKEN
                            </APISubHeader>                            
                            <APIHeader>
                                Request Body
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'folder_name' : 'Pro-folderss map systems',
                                    'exclude_reference' : 'NO',
                                    'exclude_quotes' : 'NO',
                                    'exclude_small_sources' : 'NO',
                                    'grammar_check' : 'NO',
                                    'db_studentpaper' : 'YES',
                                    'db_publications' : 'YES',
                                    'db_internet' : 'YES',
                                    'institution_repository' : 'YES',
                                    'exclude_phrases' : 'YES',
                                    'phrases' :{'p1' : 'phrases 1', 'p2' : 'phrases 2', 'p3':'Phrases 3'}
                                }
                                } />
                            </APISection>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'status': 201,
                                    'message': 'Folder - Pro-folderss map systems has been updated successfully',
                                    'timeStamp': '08-06-2023 03:00:08',
                                    '_links': {
                                        'self': {
                                            'href': 'https://s1.drillbitplagiarismcheck.com:8087/pro/folder/286040'
                                        }
                                    }
                                }} 
                                />
                            </APISection>
                        </div>
                        <DividerImgIcon src={divider}></DividerImgIcon>
                        <div id="13-content">
                            <SubHeaderTitle>
                                Delete Submission
                            </SubHeaderTitle>    
                            <APIHeader>
                                URI
                            </APIHeader>                           
                            <APISection>
                                {'https://s1.drillbitplagiarismcheck.com/pro/folder/{folder_id}/submissions?paperId={paper_id}'}
                            </APISection>
                            <div style={{fontSize:'12px', marginTop:'5px', marginLeft:'15px'}}> folder_Id : Folder ID, paper_id : Paper ID</div>
                            <APISubHeader>
                                Method Type : DELETE
                            </APISubHeader>
                            <APISubHeader>
                                HEADER : BEARER TOKEN
                            </APISubHeader>
                            <APIHeader>
                                Response
                            </APIHeader>
                            <APISection>
                                <ReactJson src={{
                                    'status': 200,
                                    'message': 'Submission deleted successfully',
                                    'timeStamp': '08-06-2023 03:03:20'
                                }} 
                                />
                            </APISection>
                        </div>
                    </Box>
                </Grid>
            </Container>
        </Section>
    );
};

OpenAPISection.propTypes = {
    menuLeftData: PropTypes.any,
    divider: PropTypes.string,
    allowedHttp: PropTypes.string,
    allowedHttpData: PropTypes.object,
    headerData: PropTypes.object,
    serverResData: PropTypes.object
};

export default OpenAPISection;
