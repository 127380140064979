import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Section } from '../../globalStyles';
import { useTranslation } from 'react-i18next';
import { gray, white } from './../../Colors';
import {
    ContentRow,
    TextWrapper,
    BannerColumn,
    SmallColumn,
    Heading,
    Subtitle,
    ContentIcon,
} from './ContentStyles.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

const Banner = (props) => {
    const {
        inverse,
        headline,
        showTitle,
        description,
        fontSize,
        img,
        regionalPadding,
    } = props;
    const { t } = useTranslation();
    const initial = { opacity: 0, y: 30 };
    const transition = { delay: 0.3, duration: 0.6 };
    const animation = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
    });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                y: 0,
            });

            return;
        }

        animation.start({
            opacity: 0,
            y: 30,
            transition: {
                duration: 0.2,
            },
        });
    }, [inView, animation]);

    return (
        <>
            <Section
                bgColor={showTitle ? white : gray}
                ref={ref}
                padding={showTitle ? '1vw 0' : regionalPadding? '1.6vw' :'1vw 0'}>
                <Container>
                    <ContentRow>
                        <SmallColumn>
                            <TextWrapper>
                                <Heading
                                    fontSize={fontSize}
                                    initial={initial}
                                    transition={transition}
                                    animate={animation}
                                    inverse={inverse}
                                >
                                    {t(`${headline}`)}
                                </Heading>
                                <Subtitle
                                    width={'800px'}
                                    initial={initial}
                                    transition={{ delay: 0.7, duration: 0.6 }}
                                    animate={animation}
                                    inverse={inverse}
                                >
                                    <div style={{textAlign:'justify'}}>{t(description)}</div>
                                </Subtitle>

                            </TextWrapper>
                        </SmallColumn>
                        <BannerColumn
                            initial={initial}
                            transition={transition}
                            animate={animation}>
                            <ContentIcon imgWidth={'99%'} src={img} /> 
                        </BannerColumn>
                    </ContentRow>
                </Container>
            </Section>
        </>
    );
};

Banner.propTypes = {
    inverse: PropTypes.bool,
    showTitle: PropTypes.bool,
    description: PropTypes.string,
    headline: PropTypes.string,
    fontSize: PropTypes.string,
    buttonLabel: PropTypes.string,
    img: PropTypes.string,
    btnShow: PropTypes.bool,
    imgWidth: PropTypes.string,
    prodType: PropTypes.object,
    trailRegistration: PropTypes.bool,
    regionalPadding: PropTypes.bool,
    isShowAicte: PropTypes.bool
};

export default Banner;