import React from 'react';

function ForgotPwd() {
    return (
        <div>
            Forgot password
        </div>
    );
}

export default ForgotPwd;
